import { Box as BaseBox } from '@material-ui/core'
import styled from 'styled-components'

const Box = styled(BaseBox)`
  background: ${({ theme }) => theme.mui.palette.background.default};
  color: ${({ theme }) => theme.mui.palette.text.primary};
  border-radius: ${({ theme }) => theme.mui.shape.borderRadius}px;
  text-align: center;
  padding: 1rem;
  font-family: 'urw-din';
`

export default Box
