import React, { createContext, useContext, useMemo } from 'react'
// import { DefaultTheme } from 'styled-components'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import lightTheme from '../../themes/lightTheme'
import darkTheme from '../../themes/darkTheme'
// import dark from '../../themes/dark'
import useMedia from 'react-use/lib/useMedia'
import { Theme } from '@material-ui/core'

export interface Props {
  children?: React.ReactNode
  /**
   * An object containing additional themes that can then be set with `setThemeName(themeName)`
   */
  themes?: { [themeName: string]: Theme }
  /**
   * Default theme to use
   * @default 'lightTheme'
   */
  themeName?: string
  /**
   * If true and the user has prefers-color-scheme dark (set in OS system preferences)
   * the default themeName is being set to `darkTheme`
   * @default false
   */
  checkPreferedColorScheme?: boolean
}

export const ThemeContext = createContext({
  /**
   * Theme that is currently being used
   */
  theme: lightTheme,
  /**
   * Currently used `themeName`
   * When set to auto, this still returns the used `themeName`
   */
  themeName: 'lightTheme',
  /**
   * The raw `themeName` being set.
   * This can also be `auto`.
   * If `checkPreferedColorScheme` and no theme was set, this returns the used `themeName`.
   */
  rawThemeName: 'lightTheme',
  /**
   * Tells the `ThemeProvider` to use a specific theme.
   * Note: themeName can also be set to `auto`, to automatically
   * check the prefered color scheme.
   * By using this method, `checkPreferedColorScheme` is being ignored
   * until resetThemeName is being called.
   */
  setThemeName: (/* eslint-disable-line */ themeName?: string) => {
    //
  },
  /**
   * Resets the changes made by using `setThemeName` by
   * removingthe value from localStorage
   */
  resetThemeName: () => {
    //
  },
})

export const useTheme = () => {
  return useContext(ThemeContext)
}

const ThemeContextProvider: React.FC<Props> = (props: Props) => {
  const { checkPreferedColorScheme } = props
  const defaultToDarkMode = useMedia('(prefers-color-scheme: dark)')
  const fallbackThemeName =
    checkPreferedColorScheme && defaultToDarkMode ? 'darkTheme' : 'lightTheme'
  const {
    children,
    themes: customThemes = {},
    themeName: defaultThemeName = fallbackThemeName,
  } = props
  const [
    lsThemeName = defaultThemeName,
    setThemeName,
    resetThemeName,
  ] = useLocalStorage<string | undefined>('theme')
  // When theme in localStorage is set to 'auto', also use the system default
  const themeName =
    lsThemeName === 'auto' && defaultToDarkMode ? 'darkTheme' : lsThemeName

  const themes = useMemo(
    () => ({
      lightTheme,
      darkTheme,
      ...customThemes,
    }),
    [customThemes]
  )
  const theme =
    (themes[themeName] as Theme) || themes[fallbackThemeName] || lightTheme

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeName,
        rawThemeName: lsThemeName,
        setThemeName,
        resetThemeName,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
