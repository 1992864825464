import React from 'react'
import styled from 'styled-components'

/**
 * Maps icon-name to corresponding unicode
 */
export const IconMap =
  /* IconNames */
  {
    visible: '\\e800',
    redo: '\\e801',
    'sort-date-down': '\\e802',
    'sort-date-up': '\\e803',
    'sub-dialog': '\\e804',
    'text-cursor': '\\e805',
    'chat-more': '\\e807',
    chat: '\\e808',
    comment: '\\e809',
    'dialog-designer': '\\e80a',
    'double-brackets': '\\e80b',
    'high-speed-train': '\\e80c',
    'load-dialog': '\\e80d',
    analytics: '\\e80e',
    'arrow-down': '\\e80f',
    'arrow-left': '\\e810',
    'arrow-right': '\\e811',
    'arrow-up': '\\e812',
    'avatar-1': '\\e813',
    'avatar-2': '\\e814',
    'avatar-3': '\\e815',
    'avatar-4': '\\e816',
    'avatar-5': '\\e817',
    'avatar-6': '\\e818',
    'avatar-7': '\\e819',
    'avatar-8': '\\e81a',
    'avatar-9': '\\e81b',
    'avatar-10': '\\e81c',
    brain: '\\e81d',
    brush: '\\e81e',
    bug: '\\e81f',
    calendar: '\\e820',
    'caret-down': '\\e821',
    'caret-left': '\\e822',
    'caret-right': '\\e823',
    'caret-solid-down': '\\e824',
    'caret-solid-right': '\\e825',
    'caret-up': '\\e826',
    checked: '\\e827',
    chess: '\\e828',
    close: '\\e829',
    'cloud-server': '\\e82a',
    coding: '\\e82b',
    'comment-more': '\\e82c',
    cursor: '\\e82e',
    'dark-theme': '\\e82f',
    database: '\\e830',
    desktop: '\\e831',
    download: '\\e832',
    facebook: '\\e833',
    flask: '\\e835',
    focus: '\\e836',
    gear: '\\e837',
    globe: '\\e838',
    heart: '\\e83a',
    hourglass: '\\e83b',
    idea: '\\e83c',
    'identity-card': '\\e83d',
    info: '\\e83e',
    instagram: '\\e83f',
    invisible: '\\e840',
    link: '\\e841',
    linkedin: '\\e842',
    location: '\\e843',
    mail: '\\e845',
    medal: '\\e846',
    megaphone: '\\e847',
    microphone: '\\e848',
    'minus-circle': '\\e849',
    minus: '\\e84a',
    more: '\\e84c',
    move: '\\e84d',
    network: '\\e84e',
    'open-book': '\\e84f',
    padlock: '\\e850',
    performance: '\\e851',
    phone: '\\e852',
    pinterest: '\\e853',
    'plus-circle': '\\e854',
    plus: '\\e855',
    quote: '\\e856',
    reddit: '\\e857',
    'robot-support': '\\e858',
    server: '\\e859',
    settings: '\\e85a',
    share: '\\e85b',
    shield: '\\e85c',
    'shopping-bag': '\\e85d',
    'shopping-cart': '\\e85e',
    shuffle: '\\e85f',
    'sort-a-z': '\\e861',
    'sort-z-a': '\\e862',
    'sound-waves-circle': '\\e863',
    speedometer: '\\e864',
    star: '\\e865',
    support: '\\e866',
    swear: '\\e867',
    teamwork: '\\e868',
    'text-box': '\\e86a',
    'text-cursor-input': '\\e86b',
    thunder: '\\e86c',
    tiktok: '\\e86d',
    translate: '\\e86e',
    trash: '\\e86f',
    twitter: '\\e870',
    'video-game': '\\e871',
    volume: '\\e872',
    warning: '\\e873',
    whatsapp: '\\e874',
    xing: '\\e875',
    youtube: '\\e876',
    hash: '\\e877',
    logout: '\\e878',
    cubes: '\\e879',
    'more-vertical': '\\e87a',
    finish: '\\e87b',
    telegram: '\\e87c',
    sing: '\\e87d',
    aristech: '\\e87e',
    'phone-alt': '\\e87f',
    brackets: '\\e880',
    graphql: '\\e881',
    'sound-waves': '\\e882',
    menu: '\\e883',
    space: '\\e884',
    moon: '\\e885',
    user: '\\e886',
    privacy: '\\e887',
    sun: '\\e888',
  }
/* /IconNames */

export type IconName = keyof typeof IconMap
export const IconNames: IconName[] = Object.keys(IconMap) as any

// type IconNamesType = typeof IconNames
// export type IconName = IconNamesType[keyof IconMap]

export interface IconProps {
  name: IconName
}

const IconEl = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:before {
    margin: 0;
    font-size: 1em;
    color: inherit;
  }
`

const Icon = styled(({ name, className = '', ...rest }) => (
  <IconEl className={`aticon-${name} ${className}`} {...rest} />
))<IconProps>``

export default Icon
