import React from 'react'
import {
  OutlinedInput as BaseInput,
  OutlinedInputProps,
} from '@material-ui/core'

const Input: React.FC<OutlinedInputProps> = (props: OutlinedInputProps) => {
  return <BaseInput margin="dense" {...props} />
}

export default Input
