import Color from 'color'

export const White = '#fff'
export const Whitish = new Color(White).darken(0.04).rgb().toString()
export const Black = '#212121'
export const Blackish = '#333'

export const PrimaryColor = 'rgb(101, 146, 225)'
export const PrimaryLighColor = new Color(PrimaryColor).lighten(0.2).toString()
export const PrimaryDarkColor = new Color(PrimaryColor).darken(0.2).toString()

// const SecondaryColor = 'rgb(170, 184, 194)'
// const SecondaryLighColor = new Color(SecondaryColor).lighten(0.2).toString()
// const SecondaryDarkColor = new Color(SecondaryColor).darken(0.2).toString()
export const SecondaryColor = 'rgb(242, 80, 80)'
export const SecondaryLightColor = new Color(SecondaryColor)
  .lighten(0.2)
  .toString()
export const SecondaryDarkColor = new Color(SecondaryColor)
  .darken(0.2)
  .toString()

export const ErrorColor = 'rgb(242, 80, 80)'
export const ErrorLightColor = new Color(ErrorColor).lighten(0.2).toString()
export const ErrorDarkColor = new Color(ErrorColor).darken(0.2).toString()

export const WarningColor = 'rgb(255, 188, 100)'
export const WarningLightColor = new Color(WarningColor).lighten(0.1).toString()
export const WarningDarkColor = new Color(WarningColor).darken(0.1).toString()

export const InfoColor = 'rgb(93, 199, 122)'
export const InfoLightColor = new Color(InfoColor).lighten(0.1).toString()
export const InfoDarkColor = new Color(InfoColor).darken(0.1).toString()

export const SuccessColor = 'rgb(93, 199, 122)'
export const SuccessLightColor = new Color(SuccessColor).lighten(0.1).toString()
export const SuccessDarkColor = new Color(SuccessColor).darken(0.1).toString()

export const FontFamily = '"urw-din", "Helvetica", "Arial", sans-serif'
