import React, { useMemo } from 'react'
import Icon, { IconName, IconNames, IconProps } from '../Icon'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${Icon}:before {
    padding: 0.75em;
    color: ${({ theme }) => theme.mui.palette.text.primary};
  }
  ${Icon} {
    font-size: 25px;
    transition: transform
      ${({ theme }) => theme.mui.transitions.duration.short}ms
      ${({ theme }) => theme.mui.transitions.easing.easeInOut};
    transform-origin: center center;
    &:hover {
      transform: scale(1.5);
    }
  }
`

interface Props {
  onSelect?: (iconName: IconName) => void
  filter?: (iconName: IconName) => boolean
  title?: (iconName: IconName) => string
  iconProps?: IconProps
}

const IconGrid: React.FC<Props> = (props: Props) => {
  const {
    onSelect,
    filter = () => true,
    title = (name) => name,
    iconProps,
  } = props
  const icons = IconNames.filter(filter)
  const onSelectName = useMemo(() => {
    const callbacks = {}
    for (let i = 0; i < icons.length; ++i) {
      const name = icons[i]
      callbacks[name] = onSelect ? () => onSelect(name) : undefined
    }
    return callbacks
  }, [onSelect, icons])
  return (
    <Wrapper>
      {icons.map((name) => (
        <Icon
          name={name}
          key={name}
          title={title(name)}
          onClick={onSelectName[name]}
          {...iconProps}
        />
      ))}
    </Wrapper>
  )
}

export default IconGrid
