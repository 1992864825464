import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import Alert from '../Alert'
import PlayPause from '../PlayPause'
import Stack from '../Stack'
import { UNSUPORTED_TEXT, WAVE_HEIGHT } from './constants'
import supportsType from './supportsType'
import useWaveSurfer, { AudioSource } from './useWaveSurfer'

export interface Props {
  src: AudioSource | [AudioSource]
  alt?: string
  fallback?: React.ReactNode
  ariaPlayingLabel?: string
  ariaPausedLabel?: string
  loadingText?: string
  unsupportedText?: string
}

const Loading = styled(Stack)`
  align-items: center;
`
const Wave = styled.span`
  display: block;
  width: 100%;
  height: ${WAVE_HEIGHT}px;
  position: relative;
  > wave > wave {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: ${({ theme }) => theme.mui.palette.primary.main};
      transform: scaleY(0);
      transition: transform
        ${({ theme }) => theme.mui.transitions.duration.shortest}ms
        ${({ theme }) => theme.mui.transitions.easing.easeInOut};
    }
  }
  > wave {
    ::-webkit-scrollbar {
      display: none;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      display: none;
      background: #eee;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      display: none;
      background: #ccc;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      display: none;
      background: #666;
    }
  }
`
const Container = styled(Stack)`
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  font-family: ${({ theme }) => theme.mui.typography.fontFamily};
  &.loading {
    display: block;
    > * {
      visibility: hidden;
    }
    > ${Loading} {
      visibility: visible;
    }
  }
  &.playing {
    ${Wave} > wave {
      ::-webkit-scrollbar,
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb:hover {
        display: block;
      }
      & > wave:after {
        transform: scaleY(1);
      }
    }
  }
  &:hover ${Wave} > wave {
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
      display: block;
    }
  }
`
const Time = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.mui.palette.text.primary};
  padding-right: 1em;
`

const AudioPlayer: React.FC<Props> = (props: Props) => {
  const {
    src,
    alt = '',
    ariaPlayingLabel = 'Playing',
    ariaPausedLabel = 'Paused',
    loadingText = 'Loading...',
    unsupportedText = UNSUPORTED_TEXT,
  } = props
  const srcSet = Array.isArray(src) ? src : [src]
  const supportedSrc = srcSet.find(({ type }) => supportsType(type))
  const {
    playing,
    currentTime,
    duration,
    ready,
    playPauseBtnRef,
    waveContainerRef,
    player,
  } = useWaveSurfer({ supportedSrc })

  if (!supportedSrc) {
    return <Alert severity="warning">{unsupportedText}</Alert>
  }

  const classNames: Array<string> = []
  if (ready) {
    classNames.push('ready')
  } else {
    classNames.push('loading')
  }
  if (playing) {
    classNames.push('playing')
  }

  return (
    <Container horizontal className={classNames.join(' ')} aria-label={alt}>
      {!ready && (
        <Loading horizontal margin="dense">
          <CircularProgress /> <span>{loadingText}</span>
        </Loading>
      )}
      <PlayPause
        onClick={() => {
          // player.load(supportedSrc.src)
          player?.playPause()
        }}
        isPlaying={playing}
        tabIndex={0}
        aria-pressed={playing}
        ref={playPauseBtnRef}
        aria-label={playing ? ariaPlayingLabel : ariaPausedLabel}
      />
      <Wave ref={waveContainerRef} />
      <Time>
        {currentTime} / {duration}
      </Time>
    </Container>
  )
}

export default AudioPlayer
