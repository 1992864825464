import React from 'react'

interface Props {
  fallback: React.ReactNode
  onError?: (error: Error, errorInfo: any) => void
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.props.onError?.(error, errorInfo)
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError } = this.state
    const { fallback, children } = this.props
    if (hasError) {
      // You can render any custom fallback UI
      return fallback
    }

    return children
  }
}

export default ErrorBoundary
