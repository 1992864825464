import { createMuiTheme } from '@material-ui/core'
import {
  Black,
  White,
  PrimaryColor,
  PrimaryLighColor,
  PrimaryDarkColor,
  SecondaryColor,
  SecondaryLightColor,
  SecondaryDarkColor,
  ErrorColor,
  ErrorLightColor,
  ErrorDarkColor,
  WarningColor,
  WarningLightColor,
  WarningDarkColor,
  SuccessColor,
  SuccessLightColor,
  SuccessDarkColor,
  FontFamily as fontFamily,
  Whitish,
} from './constants'

const theme = createMuiTheme({
  palette: {
    common: {
      black: Black,
      white: White,
    },
    primary: {
      light: PrimaryLighColor,
      main: PrimaryColor,
      dark: PrimaryDarkColor,
      contrastText: White,
    },
    secondary: {
      light: SecondaryLightColor,
      main: SecondaryColor,
      dark: SecondaryDarkColor,
      contrastText: White,
    },
    error: {
      light: ErrorLightColor,
      main: ErrorColor,
      dark: ErrorDarkColor,
      contrastText: White,
    },
    warning: {
      light: WarningLightColor,
      main: WarningColor,
      dark: WarningDarkColor,
      contrastText: White,
    },
    success: {
      light: SuccessLightColor,
      main: SuccessColor,
      dark: SuccessDarkColor,
      contrastText: White,
    },
    text: {
      primary: Black,
    },
    action: {
      hoverOpacity: 0.1,
    },
    background: {
      default: Whitish,
      paper: White,
    },
  },
  // shadows: shadows as any,
  typography: {
    fontFamily,
    h1: { fontFamily },
    h2: { fontFamily },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: { fontFamily },
    subtitle1: { fontFamily },
    subtitle2: { fontFamily },
    body1: { fontFamily },
    body2: { fontFamily },
    button: { fontFamily },
    caption: { fontFamily },
    overline: { fontFamily },
  },
})

export default theme
