import React, { ForwardedRef, forwardRef } from 'react'
import {
  capitalize,
  CircularProgress as BaseCircularProgress,
  CircularProgressProps,
  IconButtonProps,
  fade,
} from '@material-ui/core'
import styled from 'styled-components'
import BaseIconButton from '../IconButton'
import { PaletteColor } from '@material-ui/core/styles/createPalette'

interface Props extends IconButtonProps {
  // Omit<IconButtonProps, 'size'> {
  isPlaying: boolean
  // size?: 'small' | 'medium' | 'large'
  isLoading?: boolean
  circularProgressProps?: Partial<CircularProgressProps>
  variant?: 'contained' | 'text'
}

const Icon = styled.span`
  content: ' ';
  display: block;
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  border-style: solid;
  border-width: 0.5em 0px 0.5em 1em;
  transition: 0.2s all ease;
  transform: translateX(0.1em);
  &.isPlaying {
    border-style: double;
    border-width: 0px 0px 0px 1em;
    transform: none;
  }
  &.AriPlayPauseIcon-sizeMedium,
  &.AriPlayPauseIcon-sizeSmall {
    transform: scale(0.7) translateX(0.1em);
    &.isPlaying {
      transform: scale(0.7);
    }
  }
`

const CircularProgress = styled(BaseCircularProgress)`
  position: absolute;
`

const IconButton = styled(BaseIconButton).attrs((props) => {
  const { color, theme } = props
  const $paletteColor: PaletteColor =
    color && color !== 'inherit' && color !== 'default'
      ? theme.mui.palette[color]
      : theme.mui.palette.primary
  return {
    $paletteColor,
  }
})`
  position: relative;
  ${Icon} {
    border-color: transparent transparent transparent
      ${({ $paletteColor }) => $paletteColor.contrastText};
  }
  &.AriPlayPause-variantText {
    background: transparent;
    ${Icon} {
      border-color: transparent transparent transparent
        ${({ $paletteColor }) => $paletteColor.main};
    }
    &&:focus {
      background: transparent;
    }
    &&:hover {
      background: ${({ theme }) =>
        fade(
          theme.mui.palette.action.active,
          theme.mui.palette.action.hoverOpacity
        )};
    }
    &&:disabled {
      background: transparent;
      ${Icon} {
        border-color: transparent transparent transparent
          ${({ $paletteColor }) => $paletteColor.light};
      }
    }
  }
`

const PlayPause: React.FC<Props> = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      isPlaying,
      isLoading,
      size = 'medium',
      // size = 'medium',
      variant = 'contained',
      circularProgressProps = {},
      ...buttonProps
    } = props
    const classNames = [`AriPlayPause-size${capitalize(size)}`]
    const iconClassNames = [`AriPlayPauseIcon-size${capitalize(size)}`]
    if (isPlaying) {
      iconClassNames.push('isPlaying')
    }
    let progressSize = 68 // 64
    if (variant === 'text') {
      classNames.push('AriPlayPause-variantText')
      progressSize = 44
    }
    // let progressSize = 44 // 64
    // if (size === 'medium') {
    //   progressSize = 44
    // } else
    if (size === 'small') {
      progressSize = 32
    }

    return (
      <IconButton
        variant="contained"
        size={size}
        // size={size !== 'large' ? size : 'medium'}
        className={classNames.join(' ')}
        ref={ref}
        {...buttonProps}
      >
        <Icon className={iconClassNames.join(' ')} />
        {isLoading && (
          <CircularProgress size={progressSize} {...circularProgressProps} />
        )}
      </IconButton>
    )
  }
)
PlayPause.displayName = 'AriPlayPause'

export default PlayPause
