import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import * as moment from 'moment'
import { useTheme } from '../ThemeProvider/ThemeContext'
import unmuteAudio from 'unmute-ios-audio'
import { WAVE_HEIGHT } from './constants'

// eslint-disable-next-line
const CursorPlugin = require('wavesurfer.js/dist/plugin/wavesurfer.cursor.js')

/**
 * Formats duration to `hh:mm:ss`
 * @param duration The duration
 */
const formatDuration = (duration: moment.Duration) => {
  let str = ''
  const h = duration.hours()
  if (h > 0) {
    const hh = h < 10 ? `0${h}` : `${h}`
    str += `${hh}:`
  }
  const m = duration.minutes()
  const mm = m < 10 ? `0${m}` : `${m}`
  str += `${mm}:`
  const s = duration.seconds()
  const ss = s < 10 ? `0${s}` : `${s}`
  str += ss
  return str
}

export interface AudioSource {
  src: string
  type: string
}

interface Options {
  supportedSrc?: AudioSource
}

const useWaveSurfer = ({ supportedSrc }: Options) => {
  const { theme } = useTheme()
  const waveSurferRef = useRef<WaveSurfer>()
  const playPauseBtnRef = useRef<HTMLButtonElement>(null)
  const waveContainerRef = useRef<HTMLElement>(null)
  const [playing, setPlaying] = useState(false)
  const [ready, setReady] = useState(false)
  const [currentTime, setCurrentTime] = useState('-')
  const [duration, setDuration] = useState('-')
  const [, setError] = useState()

  const src = supportedSrc?.src

  // Initialize wavesurfer hook
  useEffect(() => {
    if (!src) {
      return
    }
    const container = waveContainerRef.current
    if (!container) {
      return
    }

    const player = WaveSurfer.create({
      barWidth: 3,
      barRadius: 3,
      barMinHeight: 2,
      cursorWidth: 1,
      container,
      // backend: 'WebAudio',
      height: WAVE_HEIGHT,
      progressColor: theme.palette.primary.main,
      cursorColor: 'transparent',
      autoCenter: true,
      scrollParent: true,
      partialRender: true,
      responsive: true,
      waveColor: theme.palette.grey[300],
      skipLength: 1,
      plugins: [
        CursorPlugin.create({
          showTime: true,
          hideOnBlur: true,
          opacity: 1,
          color: theme.palette.text.primary,
          customShowTimeStyle: {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.background.default,
            padding: '5px 10px',
            fontSize: '1rem',
            borderRadius: '5px',
          },
        }),
      ],
    })
    // Set ref to the player
    waveSurferRef.current = player
    player.load(src)
    player.on('play', () => {
      setPlaying(true)
      // player.setCursorColor('rgba(0,0,0,.1)')
      player.setCursorColor(theme.palette.primary.main)
    })
    player.on('pause', () => {
      setPlaying(false)
      player.setCursorColor('transparent')
    })
    const updateTime = () => {
      const currentTime = moment.duration(player.getCurrentTime(), 'seconds')
      setCurrentTime(formatDuration(currentTime))
    }
    player.on('seek', () => {
      updateTime()
      // Show cursor on active seeking
      // player.setCursorColor('rgba(0,0,0,.1)')
      player.setCursorColor(theme.palette.primary.main)
      const ppBtn = playPauseBtnRef.current
      if (ppBtn) {
        ppBtn.focus()
      }
    })
    player.on('ready', () => {
      const duration = moment.duration(player.getDuration(), 'seconds')
      setDuration(formatDuration(duration))
      setReady(true)
    })
    player.on('audioprocess', () => {
      updateTime()
    })
    player.on('error', (err: Error) => {
      // console.error('WaveSurfer error', err)
      setError(() => {
        throw err
      })
    })
    return () => {
      // console.log('Destroying player')
      player.destroy()
    }
  }, [
    waveContainerRef.current,
    playPauseBtnRef.current,
    theme,
    src,
    setPlaying,
    setReady,
    setDuration,
    setCurrentTime,
  ])
  // Keyboard seeking hook
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const player = waveSurferRef.current
      if (!player) {
        return
      }
      const isFocused =
        playPauseBtnRef.current &&
        document.activeElement === playPauseBtnRef.current
      if (!player.isPlaying() && !isFocused) {
        return
      }
      if (event.key === 'ArrowLeft') {
        player.skipBackward()
      } else if (event.key === 'ArrowRight') {
        player.skipForward()
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [waveSurferRef.current, playPauseBtnRef.current])

  // Unmute iOS
  useEffect(() => unmuteAudio(), [])

  return {
    playing,
    currentTime,
    duration,
    ready,
    playPauseBtnRef,
    waveContainerRef,
    player: waveSurferRef.current,
  }
}

export default useWaveSurfer
