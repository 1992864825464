import React, { useMemo } from 'react'
import {
  FormControl as BaseFormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  OutlinedInputProps,
  Select as BaseSelect,
  SelectProps,
} from '@material-ui/core'
import getUniqueId from '../../utils/uniqueId'
import styled from 'styled-components'
import Input from '../Input'

interface Props {
  children: React.ReactNode
  label?: string
  value?: string
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void
  formControlProps?: FormControlProps
  inputLabelProps?: InputLabelProps
  selectProps?: SelectProps
  inputProps?: OutlinedInputProps
}

const FormControl = styled(BaseFormControl)`
  min-width: 120px;
`

const Select: React.FC<Props> = (props: Props) => {
  // return <BaseSelect variant="outlined" {...props} />
  const {
    label,
    children,
    formControlProps,
    inputLabelProps,
    selectProps: baseSelectProps,
    inputProps: baseInputProps,
    value,
    onChange,
  } = props
  const uniqueId = useMemo(() => getUniqueId(), [])
  const selectId = baseSelectProps?.id || inputLabelProps?.htmlFor || uniqueId
  const selectProps = {
    id: selectId,
    label,
    value,
    onChange,
    ...baseSelectProps,
  }
  const inputProps = { label, ...baseInputProps }

  return (
    <FormControl variant="outlined" margin="dense" {...formControlProps}>
      {label && (
        <InputLabel htmlFor={selectId} {...inputLabelProps}>
          {label}
        </InputLabel>
      )}
      <BaseSelect
        input={<Input {...inputProps} />}
        variant="outlined"
        {...selectProps}
      >
        {children}
      </BaseSelect>
    </FormControl>
  )
}

export default Select
