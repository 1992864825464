import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

interface Props {
  /**
   * Switches the orientation to be horizontal
   */
  horizontal?: boolean
  /**
   * Adjusts the spacing between the items
   */
  margin?: 'normal' | 'dense'
}

interface InnerProps {
  $horizontal?: boolean
  $margin: FlattenSimpleInterpolation
}

const Stack = styled.section.attrs<Props, InnerProps>((props) => {
  const { horizontal, margin, theme, ...rest } = props
  const space = margin === 'dense' ? 1 : 2
  const $margin = horizontal
    ? css`
        margin-left: ${theme.mui.spacing(space)}px;
      `
    : css`
        margin-top: ${theme.mui.spacing(space)}px;
      `
  return {
    $horizontal: horizontal,
    $margin,
    ...rest,
  }
})<Props>`
  display: flex;
  flex-direction: ${({ $horizontal }) => ($horizontal ? 'row' : 'column')};
  & > * + * {
    ${({ $margin }) => $margin}
  }
`

export default Stack
