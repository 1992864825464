const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const getUniqueId = (segments = 2, seperator = '-') => {
  const s: string[] = []
  for (let i = 0; i < segments; ++i) {
    s.push(s4())
  }
  return s.join(seperator)
}

export default getUniqueId
