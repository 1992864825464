import React from 'react'
import {
  StylesProvider,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import ThemeContextProvider, { useTheme, Props } from './ThemeContext'

/**
 * This component actually adds the styled-components ThemeProvider
 */
const ActualThemeProvider: React.FC<Props> = (props: Props) => {
  const { children } = props
  const { theme } = useTheme()
  // return <ThemeProvider theme={theme}>{children}</ThemeProvider>
  return (
    <StylesProvider>
      <MUIThemeProvider theme={theme}>
        <SCThemeProvider theme={{ mui: theme }}>{children}</SCThemeProvider>
      </MUIThemeProvider>
    </StylesProvider>
  )
}

const ThemeProvider: React.FC<Props> = (props: Props) => {
  const { themes, themeName, checkPreferedColorScheme, children } = props
  return (
    <ThemeContextProvider
      themes={themes}
      themeName={themeName}
      checkPreferedColorScheme={checkPreferedColorScheme}
    >
      <ActualThemeProvider>{children}</ActualThemeProvider>
    </ThemeContextProvider>
  )
}

export default ThemeProvider
