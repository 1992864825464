import {
  ExtendButtonBase,
  IconButton as BaseIconButton,
  IconButtonTypeMap,
} from '@material-ui/core'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import Color from 'color'
import styled from 'styled-components'

export type IconButtonProps = ExtendButtonBase<
  IconButtonTypeMap<
    {
      variant?: 'default' | 'contained'
    },
    'button'
  >
>

const IconButton = styled<IconButtonProps>(BaseIconButton).attrs((props) => {
  const { color, theme, variant, className, disableFocusRipple = true } = props
  const $paletteColor: PaletteColor =
    color && color !== 'inherit'
      ? theme.mui.palette[color]
      : theme.mui.palette.primary
  const $borderColor = Color($paletteColor.main).alpha(0.5).toString()
  const classNames = className?.split(' ') || []
  if (variant === 'contained') {
    classNames.push('AriIconButton-contained')
  }
  return {
    $borderColor,
    $paletteColor,
    color,
    variant,
    disableFocusRipple,
    className: classNames.join(' '),
  }
})`
  &.AriIconButton-contained {
    background: ${({ $paletteColor }) => $paletteColor.main};
    color: ${({ $paletteColor }) => $paletteColor.contrastText};
    transition: background
      ${({ theme }) => theme.mui.transitions.duration.short}ms
      ${({ theme }) => theme.mui.transitions.easing.easeInOut};

    box-shadow: none;
    &:focus {
      outline: 0;
      // box-shadow: 0 0 0 3px ${({ $borderColor }) => $borderColor};
      background: ${({ $paletteColor }) =>
        Color($paletteColor.main).darken(0.1).toString()};
    }
    &:disabled {
      cursor: not-allowed;
      background: ${({ $paletteColor }) => $paletteColor.light};
      color: ${({ $paletteColor }) => $paletteColor.contrastText};
      opacity: 0.7;
    }
    &:hover:not([disabled]) {
      // box-shadow: 0 0 0 4px ${({ $borderColor }) => $borderColor};
      background: ${({ $paletteColor }) => $paletteColor.dark};
    }
  }
`

export default IconButton
