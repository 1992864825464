import React from 'react'
import styled, { keyframes } from 'styled-components'
// import { ReactComponent as SVG } from './icon.svg';

const DashAnimation = keyframes`
  25% {
    opacity: 1;
  }
  50% {
    stroke-dashoffset: 2000;
  }
  75% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 1000;
    opacity: 0;
  }
`

const Wrapper = styled.div`
  svg {
    max-width: 100%;
    max-height: 100%;
    .maskPath {
      fill-rule: evenodd;
      fill: none;
      stroke: white;
      stroke-width: 100;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;

      stroke-dasharray: 100;
      animation: ${DashAnimation};
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-duration: 2s;
      animation-direction: forwards;
      stroke-dashoffset: 3000;
      stroke-dasharray: 1000;
      opacity: 0;
    }
    .icon {
      fill-rule: evenodd;

      fill: ${({ theme }) => theme.mui.palette.primary.main};
    }
  }
`

interface Props {
  style?: React.CSSProperties
}

const IconLoader: React.FC<Props> = (props: Props) => {
  const { style } = props
  return (
    <Wrapper style={style}>
      <svg x="0px" y="0px" viewBox="0 0 467 298">
        <mask id="iconPathMask">
          <path
            className="maskPath"
            d="
	M393.8,91.3C455,121.2,458.3,250.5,356,262C221.5,251.5,287.5,62.5,181,42C73,42,32,189,50,242c26.7,29.6,64.4,38.2,141.7-20.2"
          />
        </mask>
        <path
          className="icon"
          d="M183.8,5.7c104.6,0,50,233.5,166.4,233.5c96.3,0,95.1-156.1,5.9-152.9l0,0
	c119.1-33.8,153.9,201.5-3.2,201.5c-117.2,0-85.1-204.8-171.7-204.2C69.3,84.4,3,390.2,220.4,191.8C186.8,256.2,61.9,342,19.1,242.2
	C-20,151.1,91.3,5.7,183.8,5.7z"
          mask="url(#iconPathMask)"
        />
      </svg>
    </Wrapper>
  )
}

export default IconLoader
