import React from 'react'
import styled, { css } from 'styled-components'

const light = css`
  background: repeating-linear-gradient(
      30.5deg,
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.07) 1px,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 0) 100px
    ),
    repeating-linear-gradient(
      -30.5deg,
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.07) 1px,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 0) 100px
    ),
    linear-gradient(60deg, rgba(62, 62, 201, 1) 0%, rgba(86, 203, 249, 1) 100%);
`
const dark = css`
  background: repeating-linear-gradient(
      30.5deg,
      rgba(255, 255, 255, 0.07),
      rgba(255, 255, 255, 0.07) 1px,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0) 100px
    ),
    repeating-linear-gradient(
      -30.5deg,
      rgba(255, 255, 255, 0.07),
      rgba(255, 255, 255, 0.07) 1px,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0) 100px
    ),
    linear-gradient(60deg, rgb(19 5 76) 0%, rgb(61 106 152) 100%);
`

export const MightyBlueStyle = css`
  transition: all ${({ theme }) => theme.mui.transitions.duration.short}ms
    ${({ theme }) => theme.mui.transitions.easing.easeInOut};
  ${({ theme }) => (theme.mui.palette.type === 'light' ? light : dark)}
  color: ${({ theme }) => theme.mui.palette.common.white};
`

const Wrapper = styled.div`
  ${MightyBlueStyle}
`

interface Props {
  children: React.ReactNode
  style?: React.CSSProperties
}

const MightyBlue: React.FC<Props> = (props: Props) => {
  const { children, style } = props
  return <Wrapper style={style}>{children}</Wrapper>
}

export default MightyBlue
