// import React from 'react'
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from '@material-ui/core'
import styled from 'styled-components'
import Color from 'color'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import containedStyle from './ContainedStyle'
// import { ForwardedRef, forwardRef } from 'react'

const Button = styled(BaseButton).attrs((props) => {
  const {
    color,
    theme,
    variant = 'outlined',
    size = 'medium',
    disableFocusRipple = true,
    className,
    ...rest
  } = props
  const $paletteColor: PaletteColor =
    color && color !== 'inherit'
      ? theme.mui.palette[color]
      : theme.mui.palette.grey
  const $borderColor = Color($paletteColor.main).alpha(0.5).toString()
  const classNames = className?.split(' ') || []
  if (variant === 'contained') {
    classNames.push('AriButton-contained')
  }
  const $containedStyle = containedStyle({ $paletteColor, $borderColor })
  return {
    // $paletteColor,
    // $borderColor,
    $containedStyle,
    className: classNames.join(' '),
    disableFocusRipple,
    variant,
    size,
    ...rest,
  }
})`
  &.AriButton-contained {
    ${({ $containedStyle }) => $containedStyle}
  }
`

export type ButtonProps = BaseButtonProps

/*
box-shadow: none;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${({ $borderColor }) => $borderColor};
    }
    &:disabled {
      cursor: not-allowed;
      background: ${({ $paletteColor }) => $paletteColor.light};
      color: ${({ $paletteColor }) => $paletteColor.contrastText};
      opacity: 0.7;
    }
    &:hover:not([disabled]) {
      background: ${({ $paletteColor }) => $paletteColor.main};
      box-shadow: 0 0 0 4px ${({ $borderColor }) => $borderColor};
    }
    */

export default Button
