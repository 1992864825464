import React, {
  Children,
  ForwardedRef,
  forwardRef,
  isValidElement,
} from 'react'
import {
  ButtonGroup as BaseButtonGroup,
  ButtonGroupProps,
  Button,
} from '@material-ui/core'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import containedStyle from '../Button/ContainedStyle'
import styled from 'styled-components'
import Color from 'color'

const StyledButtonGroup = styled(BaseButtonGroup).attrs((props) => {
  const { color, variant, theme, className = '' } = props
  const $paletteColor: PaletteColor = (color &&
  color !== 'inherit' &&
  color !== 'default'
    ? theme.mui.palette[color]
    : theme.mui.palette.grey) as any
  const $borderColor = Color($paletteColor.dark).alpha(0.5).toString()
  const classNames = className.split(' ')
  if (variant === 'contained') {
    classNames.push('AriButtonGroup-contained')
  }
  const $containedStyle = containedStyle({
    $paletteColor,
    $borderColor,
  })
  return {
    $containedStyle,
    className: classNames.join(' '),
    ...props,
  }
})`
  &.AriButtonGroup-contained {
    & > * {
      ${({ $containedStyle }) => $containedStyle}
    }
  }
`

const ButtonGroup: React.FC<ButtonGroupProps> = forwardRef(
  (props: ButtonGroupProps, ref: ForwardedRef<HTMLDivElement>) => {
    // return <BaseButtonGroup disableElevation size="medium" {...props} />
    const { children, ...rest } = props

    return (
      <StyledButtonGroup
        disableElevation
        disableFocusRipple
        size="medium"
        ref={ref}
        {...rest}
      >
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return null
          }
          // return cloneElement(child, { ...child.props })
          return <Button {...child.props} />
        })}
      </StyledButtonGroup>
    )
  }
)
ButtonGroup.displayName = 'AriButtonGroup'

export default ButtonGroup
