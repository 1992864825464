import React, { useCallback, useState } from 'react'
import {
  InputAdornment,
  InputAdornmentProps,
  TextField as BaseTextField,
  TextFieldProps,
} from '@material-ui/core'
import styled from 'styled-components'
import Icon, { IconProps } from '../Icon'
import IconButton, { IconButtonProps } from '../IconButton'

const TextField = styled(BaseTextField)`
  .AriTextFieldPassword {
    padding-right: 0;
  }
`

type Props = TextFieldProps & {
  showPassword?: boolean
  onToggleVisibility?: () => void
  /**
   * @default string `Password visible`
   */
  ariaVisibleLabel?: string
  /**
   * @default string `Password invisible`
   */
  ariaInvisibleLabel?: string
  inputAdornmentProps?: InputAdornmentProps
  iconButtonProps?: IconButtonProps
  iconProps?: IconProps
}

const TextFieldPassword: React.FC<Props> = (props: Props) => {
  const {
    showPassword: controlledShowPassword,
    onToggleVisibility,
    ariaVisibleLabel = 'Password visible',
    ariaInvisibleLabel = 'Password invisible',
    inputAdornmentProps,
    iconButtonProps,
    iconProps,
    ...textFieldProps
  } = props
  const [showPassword, setShowPassword] = useState(false)

  const toggleVisiblity = useCallback(() => {
    if (onToggleVisibility) {
      onToggleVisibility()
      return
    }
    setShowPassword((show) => !show)
  }, [setShowPassword, onToggleVisibility])

  const show =
    typeof controlledShowPassword === 'boolean'
      ? controlledShowPassword
      : showPassword

  return (
    <TextField
      variant="outlined"
      size="small"
      type={show ? 'text' : 'password'}
      InputProps={{
        className: 'AriTextFieldPassword',
        endAdornment: (
          <InputAdornment position="end" {...inputAdornmentProps}>
            <IconButton
              onClick={toggleVisiblity}
              aria-label={show ? ariaVisibleLabel : ariaInvisibleLabel}
              {...iconButtonProps}
            >
              {show ? (
                <Icon
                  name="visible"
                  style={{ fontSize: '0.8em' }}
                  {...iconProps}
                />
              ) : (
                <Icon
                  name="invisible"
                  style={{ fontSize: '0.8em' }}
                  {...iconProps}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  )
}

export default TextFieldPassword
