import React, { ForwardedRef, forwardRef } from 'react'
import {
  Alert as BaseAlert,
  AlertProps as BaseAlertProps,
} from '@material-ui/lab'

export type AlertProps = BaseAlertProps

const Alert: React.FC<AlertProps> = forwardRef(
  (props: AlertProps, ref: ForwardedRef<unknown>) => {
    return <BaseAlert ref={ref} {...props} />
  }
)
Alert.displayName = 'AriAlert'

export default Alert
