import { PaletteColor } from '@material-ui/core/styles/createPalette'
import Color from 'color'
import { css } from 'styled-components'

interface ContainedStyleProps {
  $paletteColor: PaletteColor
  $borderColor: string
}
export const containedStyle = ({
  $borderColor,
  $paletteColor,
}: ContainedStyleProps) => css`
  box-shadow: none;
  &:focus {
    outline: 0;
    background: ${Color($paletteColor.main).darken(0.1).toString()};
    // box-shadow: 0 0 0 3px ${$borderColor};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${$paletteColor.light};
    color: ${$paletteColor.contrastText};
    opacity: 0.7;
  }
  &:hover:not([disabled]) {
    // background: ${$paletteColor.main};
    background: ${$paletteColor.dark};
    // box-shadow: 0 0 0 4px ${$borderColor};
  }
`

export default containedStyle
