import React from 'react'
import styled, { keyframes, useTheme } from 'styled-components'
import Color from 'color'

const MoveForever = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`

const Wrapper = styled.div`
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -8px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 140px;

    height: 100px;
  }
  &.inverted .waves {
    transform: scaleY(-1) scaleX(-1);
  }

  /* Animation */
  .parallax > use {
    animation: ${MoveForever} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  &:not(.animated) .parallax > use {
    animation: none;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 11s;
    transform: translate3d(-41px, 0, 0);
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 15s;

    transform: translate3d(3px, 0, 0);
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 20s;
    transform: translate3d(47px, 0, 0);
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 30s;
    transform: translate3d(85px, 0, 0);
  }
`

interface Props {
  animated?: boolean
  inverted?: boolean
  style?: React.StyleHTMLAttributes<HTMLDivElement>
  backgroundColor?: string
}

const Waves: React.FC<Props> = (props: Props) => {
  const theme = useTheme()
  const {
    animated = false,
    inverted = false,
    backgroundColor = theme.mui.palette.background.paper,
    style,
  } = props
  const classNames: Array<string> = []
  if (animated) {
    classNames.push('animated')
  }
  if (inverted) {
    classNames.push('inverted')
  }
  const [wave1Color, wave2Color, wave3Color] = React.useMemo(() => {
    return [
      Color(backgroundColor).alpha(0.7).toString(),
      Color(backgroundColor).alpha(0.5).toString(),
      Color(backgroundColor).alpha(0.3).toString(),
    ]
  }, [backgroundColor])
  return (
    <Wrapper className={classNames.join(' ')} style={style}>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill={wave1Color} />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill={wave2Color} />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill={wave3Color} />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill={backgroundColor} />
        </g>
      </svg>
    </Wrapper>
  )
}

export default Waves
